<template>
  <b-card>
    <FormBuilder
      label="Credit Package"
      create
      :fields="fields"
      :loading-submit="$store.state.creditPackage.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'amount',
          type: 'number',
          label: 'Amount',
          required: true,
        },
        {
          key: 'discount',
          type: 'number',
          label: 'Discount',
          required: true,
        },
        {
          key: 'price',
          type: 'number',
          label: 'Price',
          required: true,
        },
        {
          key: 'qrCode',
          label: 'QR Code',
          type: 'image',
          required: false,
        },
        {
          key: 'storeProductID',
          label: 'Store Product ID',
        },
        {
          key: 'active',
          label: 'Active',
          type: 'switch',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('creditPackage/create', {
        ...data,
        roles: ['customer'],
      })
        .then(callback)
    },
  },
}
</script>

    <style scoped>

    </style>
